<template>
  <v-sheet
    v-if="imageData"
    class="cms-image--wrap"
    :height="height"
    :class="{
      cover
    }"
  >
    <NuxtPicture
      :src="imageData?.absoluteUrl"
      :cover="cover"
      :height="heightToUse"
      :width="widthToUse"
      :loading="loading"
      fit="outside"
      format="webp"
      densities="x1 x2"
      placeholder
      :class="{ cover, ...imgClasses }"
      :alt="imageData?.alternativeText || altFallback"
    />
  </v-sheet>
</template>

<script>
export default {
  name: 'cms-image',

  props: {
    // fallback to use for the alt-tag if there isn't one set in the image itself
    altFallback: {
      type: String,
      default: '',
    },

    // image-object from strapi
    imageData: {
      type: Object,
      required: true,
    },

    // use a specific width for the image
    width: {
      type: [String, Number],
      default: null,
    },

    // use a specific height for the image
    height: {
      type: [String, Number],
      default: null,
    },

    // object of css-classes with conditions
    imgClasses: {
      type: Object,
      default: () => ({}),
    },

    // image fills available space instead of showing the whole image-content
    cover: {
      type: Boolean,
      default: true,
    },

    // load image immediately or lazy
    loading: {
      type: String,
      default: 'lazy',
      validator (value) {
        return ['eager', 'lazy'].includes(value)
      },
    },
  },

  computed: {
    heightToUse () {
      return this.getDimension(this.height)
    },

    widthToUse () {
      return this.getDimension(this.width)
    },
  },

  methods: {
    getDimension (prop) {
      if (!prop) {
        return 'auto'
      }

      return typeof prop === 'number' ? `${prop}px` : prop
    },
  },
}
</script>

<style lang="scss">
  .cms-image--wrap {
    picture {
      img {
        margin: auto;
        max-width: 100%;
        object-fit: contain;
      }

      &.cover {
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.cover {
      width: 100%;
      height: 100%;
    }
  }
</style>
