
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93xhcU7epqptMeta } from "/var/www/nuxt/pages/[slug].vue?macro=true";
import { default as _91slug_93baCfwPTTaHMeta } from "/var/www/nuxt/pages/[topics]/[slug].vue?macro=true";
import { default as _404sYEfzBtxpQMeta } from "/var/www/nuxt/pages/404.vue?macro=true";
import { default as datenschutzBAoLx1yGxlMeta } from "/var/www/nuxt/pages/datenschutz.vue?macro=true";
import { default as impressumpVyRTMMFmMMeta } from "/var/www/nuxt/pages/impressum.vue?macro=true";
import { default as indexarazEwGuWbMeta } from "/var/www/nuxt/pages/index.vue?macro=true";
import { default as sitemapTJKssR42BwMeta } from "/var/www/nuxt/pages/sitemap.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/var/www/nuxt/pages/[slug].vue")
  },
  {
    name: "topics-slug",
    path: "/:topics()/:slug()",
    component: () => import("/var/www/nuxt/pages/[topics]/[slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/var/www/nuxt/pages/404.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/var/www/nuxt/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/var/www/nuxt/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/nuxt/pages/index.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/var/www/nuxt/pages/sitemap.vue")
  }
]