import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { de } from 'vuetify/locale'
import { createVuetify } from 'vuetify'
import { VTreeview } from 'vuetify/labs/VTreeview'
import { mdiCheck, mdiClose, mdiFilterVariant, mdiMagnify, mdiMenuDown, mdiMenuRight, mdiMenu } from '@mdi/js'

import config from '@/preload/data/config.json'

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,

    components: {
      VTreeview,
    },

    icons: {
      defaultSet: 'mdi',
      aliases: {
        ...aliases,
        check: mdiCheck,
        close: mdiClose,
        'menu-down': mdiMenuDown,
        'menu-right': mdiMenuRight,
        menu: mdiMenu,
        filter: mdiFilterVariant,
        search: mdiMagnify,
      },
      sets: {
        mdi,
      },
    },

    theme: {
      themes: {
        light: {
          colors: {
            primary: config.theme?.color || '#B40025',
          },
        },
      },
    },

    locale: {
      locale: 'de',
      messages: { de }
    },
  })

  app.vueApp.use(vuetify)
})
